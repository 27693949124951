import React, { Component } from 'react'
import {
  Switch,
  Route,
} from "react-router-dom";

import "./assets/scss/style.scss";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home'
import SingleProject from './pages/SingleProject'
import { scrollTo } from 'scroll-js';

import { getWindowDimensions } from './helpers'

class App extends Component {

  state = {
    isLoaded: false,
    error: false,
    windowDimensions: [],
    margin: 1,
    projects: [],
    bodyClass : '',
    pagingOrder : []
  };
  order_desktop = [];
  order_mobile = [];
  order_desktop_paging = [];
  
  _setState = (state) => {
    this.setState(state);
  }

  init = () => {
    // this.onAppScroll();
    this.onAppResize();

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let bodyClass = '';

    if (/android/i.test(userAgent)) {
      bodyClass = "os-android";
    }
    
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      bodyClass =  "os-ios";
    }
    
    if(bodyClass)
    {
      let body = document.body;
      body.classList.add(bodyClass);
    }

  }

  onAppScroll = () => {
    let onScrollTimeout = 0;
    window.addEventListener('scroll', () => {
        clearTimeout(onScrollTimeout);
        onScrollTimeout = setTimeout(() => {
          // here
        }, 200);
    });
  }

  onAppResize = () => {
      this.setState({'windowDimensions': getWindowDimensions()});
      this.setGalleryMargin();
      let onResizeTimeout = 0;
      window.addEventListener('resize', () => {
        clearTimeout(onResizeTimeout);
        onResizeTimeout = setTimeout(() => {
          this.setState({'windowDimensions' : getWindowDimensions()});
          this.setGalleryMargin();
          this.setProjectsOrder();
        }, 200);
      });
  }
        
  setGalleryMargin = () => {
    const windowDimensions = getWindowDimensions();
    if(windowDimensions.width <= 768){
      this.setState({margin:6})
    }
    else{
      this.setState({margin:10})
    }
  }

  setProjectsOrder = () => {
    const {projects} = this.state,
          windowDimensions = getWindowDimensions();
    let order = this.order_desktop,
        pagingOrder = this.order_desktop_paging;
    if(windowDimensions.width <= 767){
      order = this.order_mobile;
      pagingOrder = this.order_mobile;
    }
    
    projects.sort( function (a, b) {
      var A = a['id'], B = b['id'];
      
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
      
    });
    
    this.setState({projects,pagingOrder});
  }
  
  backToTop = (offset=0) => {
    // window.scrollTo(0, 0);
    let scrollContainer = document.body;
    if(scrollContainer.classList.contains('os-android') || scrollContainer.classList.contains('os-ios'))
    scrollContainer = document.body.getElementsByClassName('app-container')[0];
    console.log(scrollContainer)
    scrollTo(scrollContainer, { top: offset, easing: 'ease-in-out' }).then(function(){
      console.log(offset)
    });
  }

  componentDidMount() {
      this.init();
      fetch(process.env.REACT_APP_API)
      .then(res => res.json())
      .then(
          (result) => {
            this.order_desktop = result.order_desktop;
            this.order_mobile  = result.order_mobile;
            this.order_desktop_paging  = result.order_desktop_paging;
            this.body_class    = result.body_class;
            this.setState({
                isLoaded: true,
                projects: result.projects,
                home: result.home,
                footer: result.footer,
                bodyClass: 'home',
            }, this.setProjectsOrder );
            
          },
          (error) => {
              this.setState({
                  isLoaded: true,
                  error
              });
          }
      )
  }

  render() {

    const { home, footer, bodyClass, projects, pagingOrder, margin, isLoaded, error } = this.state;

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return <div className="loading"><div>Loading...</div></div>;
    }
    else {
      return (
        <div className={`${bodyClass} app-container`}>
            
            <Header />
            
            <div className="site-content">
                <Switch>
                  <Route exact path="/" render = { () => <Home projects={projects} home={home} margin={margin} _setState={this._setState} backToTop={this.backToTop} /> } />
                  <Route path="/project/:slug" render = { () => <SingleProject projects={projects} pagingOrder={pagingOrder} _setState={this._setState} backToTop={this.backToTop} /> } />
                </Switch>
            </div>
            
            <Footer backToTop={this.backToTop} footer={footer} />
          
        </div>
      )   
    }
  }
}

export default App;
