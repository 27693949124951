import React, { Component } from 'react'
import Gallery from "react-photo-gallery";
import { withRouter, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Icon from '../components/Icon';

// import LazyLoad from 'react-lazyload';

class Home extends Component {

    state = {
        expanded : false
    }

    componentDidMount() {
        this.props.backToTop();
        this.props._setState({bodyClass:'page-home'})
    }
    
    imageRenderer = ({ index, left, top, key, photo, margin }) => {
        
        return(
            <div 
                key={`project-${photo.id}`}
                id={`project-${photo.id}`}
                style={{ margin, height: photo.height, width: photo.width, left: left, top: top, position:'absolute' }}
                className="project-thumb"
            >
                <Helmet>
                    <title>{this.props.home.og.title}</title>
                    <link rel="shortcut icon" href={this.props.home.og.img} />
                    <meta name="description" content={this.props.home.og.title} data-react-helmet="true"></meta>
                    <meta property="og:description" content={this.props.home.og.description} data-react-helmet="true"></meta>
                    {/* <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"></meta>  */}
                    <meta name="twitter:site" content="CreatingDigital" data-react-helmet="true"></meta> 
                    <meta name="twitter:description" content={this.props.home.og.description} data-react-helmet="true"></meta> 
                    <meta name="twitter:image" content={this.props.home.og.img} data-react-helmet="true"></meta> 
                    <meta property="og:title" content={this.props.home.og.title} data-react-helmet="true"></meta> 
                    <meta name="twitter:title" content={this.props.home.og.title} data-react-helmet="true"></meta> 
                </Helmet>
                <Link to={`/project/${photo.slug}`}>
                    {/* <LazyLoad height={photo.height} once offset={100}> */}
                        <img
                            alt={photo.title}
                            src={photo.src}
                            srcSet={photo.srcset}
                            sizes={photo.sizes}
                            width={photo.width}
                            height={photo.height}
                        />
                    {/* </LazyLoad> */}
                    <span className="project-title">{photo.thumb_title} <Icon name="next" /> </span>
                </Link>
            </div>
        )
    }

    columns(containerWidth) {
        let columns = 1;
        // console.log(containerWidth)
        if (containerWidth >= 756) columns = 3;
        return columns;
    }

    expandContent = () => {
        this.setState( { expanded:true } );
    }

    render() {
        return (
            <div className="container">
                <div className={`home-main ${this.state.expanded?'expanded':''}`}>
                    <div className="home-content">
                        <div dangerouslySetInnerHTML={{__html:this.props.home.content}} />
                        <div class="expand-btn btn" onClick={()=>{this.expandContent()}}>Read More <Icon name="plus" /></div>
                    </div>
                    <div class="gallery-caption">Tap image for details</div>
                    <Gallery
                        photos      = {this.props.projects} 
                        direction   = {"column"}
                        columns     = {this.columns}
                        margin      = {this.props.margin}
                        renderImage = {this.imageRenderer}
                    />
                </div>
            </div>
        )
    }

}

export default withRouter(Home);