import React, { Component } from 'react';
import {withRouter } from 'react-router-dom';
import Icon from '../components/Icon'

class Project extends Component {

    
    constructor(props){
        super(props);
        this.video = React.createRef();
        this.state = {
            playVieo: false
        }
    }

    playToggle = () => {
        if(this.state.playVideo===true) return;
        const playVideo = !this.state.playVideo,
                video = this.video.current;
        
        video.play();
        video.controls = true;
        
        this.setState( {playVideo} );
    }

    render(){
        const {playVideo} = this.state;
        const {currentProject} = this.props;

        return (
            <article className="project">
   
                   {currentProject.video.url && 
                       <div className="video" onClick={this.playToggle}>
                           <div className={`play-button ${(playVideo) ? 'hidden' : ''}`}>
                               <Icon name="play" />
                           </div>
                           
                           <video ref={this.video} key={currentProject.slug} playsInline preload="auto">
                               <source src={`${currentProject.video.url}#t=1`} type="video/mp4" />
                           </video>
                       </div>
                   }
   
                   <h1 className="title">{currentProject.title}</h1>
                   
                   <div className="meta">
                       {currentProject.address &&
                           <div className="address"> {currentProject.address} </div>
                       }
                       {currentProject.building_detail &&
                           <div className="building_detail"> {currentProject.building_detail} </div>
                       }
                   </div>
                   
                   <div className="desc" dangerouslySetInnerHTML={{__html:currentProject.description}}></div>
   
           </article>
       )
    }
}

export default withRouter(Project)