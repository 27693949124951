import React, { Component } from 'react';
import Icon from '../components/Icon';
import {withRouter,Link } from 'react-router-dom';
import Project from '../components/Project';
import {Helmet} from "react-helmet";
import { isEqual } from '../helpers'

class SingleProject extends Component {

    notFound = {
        id: -1,
        title: "Vertical",
        slug: "vertical",
        description: "",
        orientation: "",
        address: "",
        video: null,
        srcset: "",
        src: "",
        alt: "",
        width: '',
        height: ''
    }

    state = {
        currentProject : false,
        nextProject : false,
        prevProject : false
    }
    /**
     * 
     */
    componentDidMount = () => {
        const slug = this.props.match.params.slug;
        this.setCurrentProject(slug);
        this.props._setState({bodyClass:'page-project'})
    };
    
    /**
     * 
     */
    componentDidUpdate(prevProps) {
        if(this.props.match.params.slug !== prevProps.match.params.slug) {
            this.setCurrentProject(this.props.match.params.slug);
            this.props._setState({bodyClass:'page-project'})
        }
        if( !isEqual(this.props.pagingOrder, prevProps.pagingOrder) ){
            this.setCurrentProject(this.props.match.params.slug);
        }
    }
    
    /**
     * 
     */
    setCurrentProject(slug) {
        const {pagingOrder, projects} = this.props;

        let index = -1;
        const currentProject = projects.filter((p, i)=>{
            if(p.slug===slug){
                index = i; // this will be used if current project id not found in paging order
                return true;
            }
            return false;
        })

        if(currentProject.length){
            pagingOrder.filter((id,i)=>{
                if(currentProject[0].id===id){
                    index = i; //Override the one set before
                    return true;
                }
                return false;
            })

            const nextId = (typeof pagingOrder[index+1] == 'undefined') ? pagingOrder[0] : pagingOrder[index+1],
                    prevId = (index===0)  ? pagingOrder[pagingOrder.length-1] : pagingOrder[index-1];
            
            let nextProject = projects.filter((p)=>{
                return nextId===p.id;
            });
            let prevProject = projects.filter((p)=>{
                return prevId===p.id;
            });
            nextProject = nextProject.length ? nextProject[0] : false;
            prevProject = prevProject.length ? prevProject[0] : false;
            this.setState({currentProject:currentProject[0], nextProject, prevProject})
        }
        else{
            this.setState({currentProject:this.notFound, nextProject:false, prevProject:false});
        }

        // document.getElementById('root').scrollIntoView({ 
        //     behavior: 'smooth',
        //     block:    "start",
        // })
        this.props.backToTop(100);
    }
    
    /**
     * 
     */
    prevBtn = (posts,index) => {
        
        // document.getElementById('root').scrollIntoView({ behavior: 'smooth'  })
        this.props.backToTop();

        let i = --index;
        if(i < 0){ return }

        this.props.history.push({
            pathname: `/project/${posts[i].slug}`,
            state: {posts,index:i},
        })
    }
    /**
     * 
     */
    nextBtn = () => {
        
        // document.getElementById('root').scrollIntoView({ behavior: 'smooth'  })
        this.props.backToTop();
    }
    /**
     * 
     */
    render() {
        
        const {currentProject, nextProject, prevProject} = this.state;
        if(!currentProject) return null;

        // console.log(currentProject);

        return (
            <>
                <Helmet>
                    <title>{currentProject.og.title}</title>
                    <link rel="shortcut icon" href={currentProject.og.img} />
                    <meta name="description" content={currentProject.og.title} data-react-helmet="true"></meta>
                    <meta property="og:description" content={currentProject.og.description} data-react-helmet="true"></meta>
                    {/* <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"></meta>  */}
                    <meta name="twitter:site" content="CreatingDigital" data-react-helmet="true"></meta> 
                    <meta name="twitter:description" content={currentProject.og.description} data-react-helmet="true"></meta> 
                    <meta name="twitter:image" content={currentProject.og.img} data-react-helmet="true"></meta> 
                    <meta property="og:title" content={currentProject.og.title} data-react-helmet="true"></meta> 
                    <meta name="twitter:title" content={currentProject.og.title} data-react-helmet="true"></meta>
                </Helmet>
                <div className="container">
                    {currentProject.child.length===0 &&
                        <Project
                            currentProject = {currentProject}
                            nextProject    = {nextProject}
                            prevProject    = {prevProject}
                            key = {currentProject.slug}
                        />
                    }
                    
                    {currentProject.child.length>0 &&
                        <h1 className="title-top">Current Projects</h1>
                    }

                    {currentProject.child.length>0 &&
                        currentProject.child.map((subProject)=>{
                            return(
                                <Project
                                    currentProject = {subProject}
                                    key = {subProject.id}
                                />        
                            )
                        })
                    }
                </div>
                <nav className="nav">
                    <div className="container">
                        <div className="prev">
                            {prevProject &&
                                <Link to={`/project/${prevProject.slug}`}>
                                    <Icon name="prev" />
                                    <span>Prev</span>
                                </Link>
                            }
                        </div>
                        <div className="back">
                            <Link to="/">
                                <Icon name="home" />
                                <span>Back</span>
                            </Link>
                        </div>
                        <div className="next">
                            {nextProject &&
                                <Link to={`/project/${nextProject.slug}`}>
                                    <span>Next</span>
                                    <Icon name="next" />
                                </Link>
                            }
                        </div>
                    </div>
                </nav>
            </>
        )
    }
}

export default withRouter( SingleProject)