import React from 'react';
import Icon from '../components/Icon'


const Footer = (props)=>{
  if(!props.footer) return null;
  const {footer} = props

  return(
        <footer className="site-footer">
          <div className="container">
              <ul>
                <li className="backtotop"><div onClick={()=>props.backToTop(0)}><Icon name="uparrow" /></div></li>
                <li>&copy; {new Date().getFullYear()} DAVIS DESIGN &amp; DEVELOPMENT</li>
                {/* <li><a href="tel:9176991390">917.699.1390</a></li> */}
                <li><a href="mailto:gdavis@davisdesigndev.com">GDAVIS@DAVISDESIGNDEV.COM</a></li>
                <li>
                  <a href={footer.cv}>DOWNLOAD RESUME <Icon name="downarrow" /> </a>
                </li>
                <li><a href={footer.linkein} target="_blank" rel="noopener noreferrer"><Icon name="linkedin" /></a></li>
              </ul>
          </div>
          <div className="site-by">
            <div className="container">
              <a href="http://miskowskidesign.com/" target="_blank" rel="noopener noreferrer">WEBSITE DESIGN &amp; DEVELOPMENT BY MISKOWSKIDESIGN</a>
            </div>
          </div>
        </footer>
    )
}

export default Footer;