import React from 'react'

const Icon = ({name})=> {
        
    if(name==="play"){
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.13 76.13"><path d="M38.07,0A38.07,38.07,0,1,0,76.13,38.07,38.07,38.07,0,0,0,38.07,0Zm-10,56.11V20l30.18,18Z"/></svg>
        )
    }

    else if(name==='linkedin'){
        return(
            <div className="linkedin-icon round-icon icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.54 12.94">
                    <title>linkedin</title>
                    <path d="M3.07,12.94V4.21H.17v8.73ZM1.62,3a1.51,1.51,0,1,0,0-3,1.51,1.51,0,1,0,0,3Z" style={{fill:'#939393',fillRule:'evenodd'}}/>
                    <path d="M13.54,7.93C13.54,5.25,12.1,4,10.19,4A2.89,2.89,0,0,0,7.56,5.47h0V4.21H4.68c0,.82,0,8.73,0,8.73h2.9V8.06a2,2,0,0,1,.1-.71A1.59,1.59,0,0,1,9.16,6.29c1.05,0,1.47.8,1.47,2v4.67h2.9" style={{fill:'#939393',fillRule:'evenodd'}}/>
                </svg>
            </div>
        )
    }

    else if(name==='downarrow'){
        return(
            <span className="downarrow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.68 9.97">
                    <title>downarrow</title>
                    <path d="M4.34,10,0,5.63.74,4.9,3.84,8V0h1V8L7.94,4.9l.74.73Z" />
                </svg>
            </span>
        )
    }
    
    else if(name==='uparrow'){
        return(
            <div className="uparrow-icon round-icon icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.93 7.4">
                    <title>uparrow</title>
                    <polyline points="0.47 6.93 6.47 0.93 12.47 6.93" style={{fill:'none',stroke:'#7c7c7c',strokeMiterlimit:'10',strokeWidth:'1.32px'}}/>
                </svg>
            </div>
        )
    }

    else if(name==='prev'){
        return(
            <div className="icon-prev">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.81 10.27"><path d="M2.33 5.74L6 9.41l-.86.86L0 5.14 5.14 0 6 .86 2.34 4.53h9.47v1.21z" fill="#fff"/></svg>
            </div>
        )
    }
    
    else if(name==='next'){
        return(
            <div className="icon-next">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.81 10.27"><path d="M9.48 4.53L5.8.86 6.67 0l5.14 5.13-5.14 5.14-.87-.86 3.67-3.67H0V4.53z" fill="#fff"/></svg>
            </div>
        )
    }
    
    else if(name==='home'){
        return(
            <div className="icon-home">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.83 14.83"><path fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth=".5" d="M.25.25h14.33v14.33H.25zM5.03.25v14.33M9.81.25v14.33M.25 9.81h14.33M.25 5.03h14.33"/></svg>
            </div>
        )
    }

    else if(name==='plus'){
        return(
            <div className="icon-plus">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.33 10.33"><title>plus</title><line x1="5.17" x2="5.17" y2="10.33" stroke="#FFF" /><line y1="5.17" x2="10.33" y2="5.17" stroke="#FFF" /></svg>
            </div>
        )
    }

}

export default Icon;