import React from 'react'
import logo from '../assets/images/logo.svg'
import {NavLink} from "react-router-dom";

const Header = () => {
    return (
        <header className="site-header" id="site-header"> 
            <div className="container">
                <NavLink className="logo" to="/">
                    <img src={logo} alt="Davis Design &amp; Development" />
                </NavLink>
            </div>
        </header>
    )
}


export default Header;
