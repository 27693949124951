export function getWindowDimensions(){
    const ww = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth,
            wh = window.innerHeight
                || document.documentElement.clientHeight
                || document.body.clientHeight;

    return {width:ww, height:wh}
}

export function isEqual(array1, array2){
    return (array1.length === array2.length) && array1.every(function(element, index) {
        return element === array2[index]; 
    });
}